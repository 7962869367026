import React, { useState } from 'react'
import { FlatList, View, Text, ActivityIndicator } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import tailwind, { getColor } from 'tailwind-rn'
import YearPicker from '../components/YearPicker'
import { AntDesign } from '@expo/vector-icons'
import useFetch from '../utils/useFetch'
import { BASE_URL, monthYear } from '../utils/common'
import { useAuthState } from '../contexts/AuthContext'

export default function ContrachequesScreen({ navigation, userToken }) {
  const [currentYear, setCurrentYear] = useState(new Date().getUTCFullYear())

  const authState = useAuthState()

  console.log('authState', authState)

  const callback = (value) => {
    console.log(value)
    setCurrentYear(value)
  }

  const { response, error, loading } = useFetch(
    `${BASE_URL}/api/contracheques?ano=${currentYear}&codigoColaborador=${authState.codigoColaborador}`,
    {
      method: 'GET',
      headers: { Authorization: userToken },
    }
  )

  const renderItem = ({ item }) =>
    item.liquidoAReceber ? (
      <TouchableOpacity
        style={tailwind(
          `rounded-lg ${
            item.mes === '13.1' || item.mes === '13.2'
              ? 'bg-green-800'
              : 'bg-blue-800'
          } p-6 mx-5 mt-3 flex-row justify-between items-center`
        )}
        onPress={() =>
          navigation.navigate('ContrachequeModalScreen', {
            ano: currentYear,
            mes: item.mes,
            codigoColaborador: authState.codigoColaborador,
          })
        }
      >
        <View style={tailwind('flex-row')}>
          <AntDesign name="download" size={20} color={getColor('white')} />
          <Text style={tailwind('text-white uppercase font-light ml-2')}>
            {!item.is13Salario &&
              !item.isAdiantamento13Salario &&
              Object.keys(monthYear).find((key) => monthYear[key] === item.mes)}
            {item.mes === '13.2' && '13º Salário'}
            {item.mes === '13.1' && '13º Salário (Adiantamento)'}
          </Text>
        </View>
        <Text style={tailwind('text-white font-bold text-base')}>
          R$ {item.liquidoAReceber}
        </Text>
      </TouchableOpacity>
    ) : (
      <View
        style={tailwind(
          'rounded-lg bg-gray-500 p-6 mx-5 mt-3 flex-row justify-between items-center'
        )}
        onPress={() => alert('item.value')}
      >
        <View style={tailwind('flex-row items-center justify-center')}>
          <AntDesign
            name="clockcircleo"
            size={20}
            color={getColor('gray-400')}
          />
          <Text style={tailwind('text-gray-400 uppercase font-bold ml-2')}>
            {Object.keys(monthYear).find((key) => monthYear[key] === item.mes)}
          </Text>
        </View>
        <Text style={tailwind('text-gray-400 font-bold text-base')}>
          Indisponível
        </Text>
      </View>
    )

  console.log('loading', loading)
  console.log('response', response)

  return (
    <View style={tailwind('flex-col bg-gray-200 h-full w-full')}>
      <YearPicker
        onChange={callback}
        currentYear={currentYear}
        style={tailwind(
          'bg-white p-2 pt-4 mx-5 rounded-lg border border-gray-400 mt-4'
        )}
      />
      {loading && (
        <View style={tailwind('flex justify-center items-center mt-4')}>
          <ActivityIndicator size={50} color={getColor('blue-800')} />
        </View>
      )}
      {!loading &&
        !error &&
        response &&
        response.data &&
        response.data.length >= 1 && (
          <FlatList
            data={response.data}
            renderItem={renderItem}
            keyExtractor={(item) => item.mes}
            style={{ width: '100%' }}
          />
        )}
      {!loading && !error && response.data.length === 0 && (
        <View style={tailwind('bg-gray-400 py-4 mx-5 mt-4 rounded-lg')}>
          <Text style={tailwind('text-center text-sm text-gray-600')}>
            Não há contracheques para o ano selecionado
          </Text>
        </View>
      )}
    </View>
  )
}
