import React, { useMemo } from 'react'
import { Text, View } from 'react-native'
import { Picker } from '@react-native-community/picker'
import tailwind, { getColor } from 'tailwind-rn'
import { FontAwesome } from '@expo/vector-icons'

export default function YearPicker({
  style,
  onChange,
  currentYear,
  title = 'Ano de Competência',
}) {
  const years = useMemo(() => {
    const currentYear = [
      new Date().getUTCFullYear(),
      new Date().getUTCFullYear() - 1,
    ]
    return new Array(currentYear[0] - currentYear[1] + 1)
      .fill(null)
      .map((_, i) => currentYear[1] + i)
  }, [])

  return (
    <View style={style}>
      <View style={tailwind('flex-row items-center px-2')}>
        <FontAwesome name="calendar" size={15} color={getColor('gray-500')} />
        <Text style={tailwind('text-gray-500 ml-2')}>{title}</Text>
      </View>
      <Picker
        onValueChange={(value) => onChange(value)}
        prompt={'Selecione'}
        selectedValue={currentYear}
        style={tailwind('text-blue-800')}
      >
        {years.map((year) => (
          <Picker.Item key={year} label={year.toString()} value={year} />
        ))}
      </Picker>
    </View>
  )
}
