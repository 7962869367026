import React, { useEffect, useState } from 'react'
import {
  View,
  Text,
  ActivityIndicator,
  ScrollView,
  SafeAreaView,
  Platform,
} from 'react-native'
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler'
import tailwind, { getColor } from 'tailwind-rn'
import { BASE_URL, monthYear } from '../utils/common'
import useFetch from '../utils/useFetch'
import { AntDesign } from '@expo/vector-icons'
import * as FileSystem from 'expo-file-system'
import * as Sharing from 'expo-sharing'

export default function ContrachequeModalScreen({
  navigation,
  route,
  userToken,
}) {
  useEffect(() => navigation.setOptions({ title: 'Contracheque' }), [])

  const { ano, mes, codigoColaborador } = route.params

  const [loadingContrachequePdf, setLoadingContrachequePdf] = useState(false)

  const { response, loading } = useFetch(
    `${BASE_URL}/api/contracheques?ano=${ano}&mes=${mes}&codigoColaborador=${codigoColaborador}`,
    {
      method: 'GET',
      headers: { Authorization: userToken },
    },
    {}
  )

  console.log(response.ano, response.mes)

  if (!response || loading)
    return (
      <View style={tailwind('flex justify-center items-center w-full h-full')}>
        <ActivityIndicator size={50} color={getColor('blue-800')} />
      </View>
    )

  const generateContrachequeAndShare = async () => {
    try {
      setLoadingContrachequePdf(true)
      const resData = await fetch(
        `${BASE_URL}/api/contracheques/gerarPdf?ano=${ano}&mes=${mes}&codigoColaborador=${codigoColaborador}`,
        {
          method: 'GET',
          headers: { Authorization: userToken },
        }
      )
      const { pdfData } = await resData.json()
      if (Platform.OS === 'web') {
        var binary = atob(pdfData.replace(/\s/g, ''))
        var len = binary.length
        var buffer = new ArrayBuffer(len)
        var view = new Uint8Array(buffer)
        for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i)
        }
        var blob = new Blob([view], { type: 'application/pdf' })
        var url = URL.createObjectURL(blob)
        window.open(url)
      } else {
        const pdfPath = FileSystem.cacheDirectory + 'contracheque.pdf'
        await FileSystem.writeAsStringAsync(pdfPath, pdfData, {
          encoding: FileSystem.EncodingType.Base64,
        })
        await Sharing.shareAsync(pdfPath)
      }
      console.log('generated contracheque!')
      setLoadingContrachequePdf(false)
    } catch (e) {
      console.log(e)
      alert('Ocorreu um erro ao tentar gerar o PDF:', e.message)
      setLoadingContrachequePdf(false)
    }
  }

  const competencia = (() => {
    if (response.is13Salario) return '13º Salário'
    if (response.isAdiantamento13Salario) return '13º Salário (Adiantamento)'
    return `${Object.keys(monthYear).find(
      (key) => monthYear[key] === response.mes
    )} de ${response.ano}`
  })()

  const renderItem = ({ item }) => (
    <View style={tailwind('bg-gray-200 p-3 rounded-lg my-1')}>
      <View style={tailwind('flex-row justify-between pb-1')}>
        <Text style={tailwind('text-gray-600 font-bold text-xs')}>Código</Text>
        <Text style={tailwind('text-xs')}>{item.codigo}</Text>
      </View>
      <View style={tailwind('flex-row justify-between pb-1')}>
        <Text style={tailwind('text-gray-600 font-bold text-xs')}>
          Descrição
        </Text>
        <Text style={tailwind('text-xs')}>{item.descricao}</Text>
      </View>
      <View style={tailwind('flex-row justify-between pb-1')}>
        <Text style={tailwind('text-gray-600 font-bold text-xs')}>
          Referência
        </Text>
        <Text style={tailwind('text-xs')}>{item.referencia}</Text>
      </View>
      {item.provento !== '' && (
        <View style={tailwind('flex-row justify-between pb-1')}>
          <Text style={tailwind('text-gray-600 font-bold text-xs')}>
            Provento
          </Text>
          <Text style={tailwind('text-xs text-green-500 font-bold')}>
            + R$ {item.provento}
          </Text>
        </View>
      )}
      {item.desconto !== '' && (
        <View style={tailwind('flex-row justify-between')}>
          <Text style={tailwind('text-gray-600 font-bold text-xs')}>
            Desconto
          </Text>
          <Text style={tailwind('text-xs text-red-500 font-bold')}>
            - R$ {item.desconto}
          </Text>
        </View>
      )}
    </View>
  )

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView bouncesZoom={true}>
        <View style={tailwind('flex justify-center items-center mx-5')}>
          <View style={tailwind('bg-white p-3 w-full rounded-lg mt-5')}>
            <View
              style={tailwind('mt-2 flex-row justify-between items-center')}
            >
              <Text style={tailwind('text-xs text-gray-600 font-bold')}>
                COMPETÊNCIA
              </Text>
              <Text style={tailwind('text-sm text-gray-700 uppercase text-xs')}>
                {competencia}
              </Text>
            </View>
            <View
              style={tailwind('mt-2 flex-row justify-between items-center')}
            >
              <Text style={tailwind('text-xs text-gray-600 font-bold')}>
                NOME
              </Text>
              <Text style={tailwind('text-sm text-gray-700 uppercase text-xs')}>
                {response.nomeColaborador}
              </Text>
            </View>
            <View
              style={tailwind('mt-2 flex-row justify-between items-center')}
            >
              <Text style={tailwind('text-xs text-gray-600 font-bold')}>
                CARGO
              </Text>
              <Text style={tailwind('text-sm text-gray-700 uppercase text-xs')}>
                {response.cargo}
              </Text>
            </View>
            <View
              style={tailwind('mt-2 flex-row justify-between items-center')}
            >
              <Text style={tailwind('text-xs text-gray-600 font-bold')}>
                PIS
              </Text>
              <Text style={tailwind('text-sm text-gray-700 uppercase text-xs')}>
                {response.pis}
              </Text>
            </View>
            <View
              style={tailwind('mt-2 flex-row justify-between items-center')}
            >
              <Text style={tailwind('text-xs text-gray-600 font-bold')}>
                BANCO
              </Text>
              <Text style={tailwind('text-sm text-gray-700 uppercase text-xs')}>
                {response.nomeBanco}
              </Text>
            </View>
            <View
              style={tailwind('mt-2 flex-row justify-between items-center')}
            >
              <Text style={tailwind('text-xs text-gray-600 font-bold')}>
                AGÊNCIA
              </Text>
              <Text style={tailwind('text-sm text-gray-700 uppercase text-xs')}>
                {response.agencia}
              </Text>
            </View>
            <View
              style={tailwind('mt-2 flex-row justify-between items-center')}
            >
              <Text style={tailwind('text-xs text-gray-600 font-bold')}>
                CONTA
              </Text>
              <Text style={tailwind('text-sm text-gray-700 uppercase text-xs')}>
                {response.conta}
              </Text>
            </View>
            <Text
              style={tailwind(
                'mt-4 text-center text-gray-600 font-bold text-sm'
              )}
            >
              DISCRIMINAÇÃO DAS VERBAS
            </Text>
            <FlatList
              data={response.verbas}
              renderItem={renderItem}
              keyExtractor={(item) => item.codigo}
              style={{ width: '100%', marginTop: 4 }}
            />

            {!loadingContrachequePdf && (
              <TouchableOpacity
                style={tailwind(
                  'p-3 bg-blue-800 mt-4 rounded-lg flex-row justify-center items-center'
                )}
                onPress={generateContrachequeAndShare}
              >
                <AntDesign name="download" size={20} color="white" />
                <Text style={tailwind('text-white font-bold text-center ml-2')}>
                  BAIXAR
                </Text>
              </TouchableOpacity>
            )}
            {loadingContrachequePdf && (
              <View
                style={tailwind(
                  'p-3 bg-blue-800 mt-4 rounded-lg flex-row justify-center items-center'
                )}
              >
                <ActivityIndicator color="white" />
                <Text style={tailwind('text-white font-bold text-center ml-2')}>
                  Carregando...
                </Text>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}
