import React, { useEffect, useState } from 'react'
import {
  Image,
  Text,
  TextInput,
  View,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native'
import LogoNatalNacional from '../../assets/icon-natal.png'
import LogoNacional from '../../assets/icon.png'
import { useForm } from 'react-hook-form'
import { useAuthDispatch } from '../contexts/AuthContext'
import { isNewYear, signIn } from '../utils/common'
import tailwind, { getColor } from 'tailwind-rn'
import { MaterialIcons } from '@expo/vector-icons'
import Snowfall from 'react-snowfall'

export default function Login({ navigation }) {
  const { register, handleSubmit, setValue, errors } = useForm()
  const dispatch = useAuthDispatch()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('cpf', {
      required: true,
      minLength: 11,
      maxLength: 11,
    })
    register('password', { required: true, minLength: 1, maxLength: 32 })
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const resData = await signIn(data)
      dispatch({
        type: 'SIGN_IN',
        token: resData.userToken,
        isFirstLogin: resData.isFirstLogin,
      })
    } catch (e) {
      console.log(e)
      alert(e.message)
      setLoading(false)
    }
  }

  const isChritmas = new Date().getMonth() === 11

  return (
    <View
      style={tailwind(
        'flex-col justify-center items-center h-full w-full px-4 bg-blue-800'
      )}
    >
      {isChritmas ? <Snowfall /> : null}
      <Image
        source={isChritmas ? LogoNatalNacional : LogoNacional}
        style={tailwind('w-24 h-24 rounded-lg mb-8')}
      />
      <View
        style={tailwind('flex-col items-center bg-white rounded-lg p-4 w-full')}
      >
        <Text style={tailwind('my-4 text-xl font-bold text-gray-800')}>
          Faça seu login
        </Text>

        <TextInput
          keyboardType="number-pad"
          placeholder="CPF"
          style={tailwind(
            `text-blue-800 p-4 w-full my-2 text-lg rounded bg-gray-300 ${
              errors.cpf ? 'border border-red-500 text-red-500' : ''
            }`
          )}
          maxLength={11}
          onChangeText={(text) => setValue('cpf', text)}
        />
        <TextInput
          keyboardType="default"
          autoCapitalize="none"
          secureTextEntry={true}
          placeholder="Senha"
          style={tailwind(
            `text-blue-800 p-4 w-full my-2 text-lg rounded bg-gray-300 ${
              errors.password ? 'border border-red-500 text-red-500' : ''
            }`
          )}
          onChangeText={(text) => setValue('password', text)}
        />

        <View style={tailwind('my-4')}>
          <TouchableOpacity
            style={tailwind('flex-row items-center justify-center py-2')}
            onPress={() => navigation.navigate('Resetar Senha')}
          >
            <Text
              style={tailwind(
                'font-semibold text-blue-600 flex-row justify-center items-center'
              )}
            >
              Esqueci minha senha
            </Text>
            <MaterialIcons
              name="keyboard-arrow-right"
              size={20}
              color={getColor('blue-600')}
              style={tailwind('')}
            />
          </TouchableOpacity>
        </View>

        {loading ? (
          <ActivityIndicator size="large" color="#000" />
        ) : (
          <TouchableOpacity
            style={tailwind(
              'bg-blue-800 w-full px-8 py-4 rounded-lg flex items-center'
            )}
            onPress={handleSubmit(onSubmit)}
          >
            <Text
              style={tailwind('text-white text-lg font-semibold uppercase')}
            >
              CONTINUAR
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}
