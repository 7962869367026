import React, { useEffect } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { TextInput } from 'react-native-gesture-handler'
import tailwind, { getColor } from 'tailwind-rn'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useForm } from 'react-hook-form'
import { resetPassword } from '../utils/common'
import { useAuthDispatch, useAuthState } from '../contexts/AuthContext'

export default function ChangePassword() {
  const { register, setValue, handleSubmit } = useForm()
  const authState = useAuthState()
  const authDispatch = useAuthDispatch()

  useEffect(() => {
    register('newPassword')
    register('newPasswordConfirmation')
  }, [])

  const onSubmit = async (data) => {
    try {
      const newToken = await resetPassword(data, authState.userToken)
      authDispatch({ type: 'RESET_TOKEN', newToken })
    } catch (e) {
      console.log(e)
      alert(e.message)
    }
  }

  return (
    <View
      style={tailwind('absolute w-full h-full items-center justify-center')}
    >
      <View
        style={tailwind('absolute h-full w-full bg-gray-500 opacity-75')}
      ></View>
      <View
        style={tailwind('bg-white mx-10 w-full p-6 rounded-lg items-center')}
      >
        <MaterialCommunityIcons
          name="lock-reset"
          size={50}
          color={getColor('blue-800')}
        />
        <Text style={tailwind('text-blue-800 text-xl font-medium font-bold')}>
          Alterar Senha
        </Text>
        <Text style={tailwind('text-gray-600 text-center mt-2 mx-10')}>
          Crie uma nova senha para manter seus dados seguros
        </Text>
        <View style={tailwind('mt-2')}>
          <Text
            style={tailwind('text-blue-800')}
          >{`\u2022 Entre 6 a 12 caracteres`}</Text>
        </View>

        <TextInput
          maxLength={16}
          placeholder="Nova Senha"
          secureTextEntry={true}
          style={tailwind('bg-gray-200 w-full text-base p-2 mt-4 rounded-md')}
          onChangeText={(text) => setValue('newPassword', text)}
        ></TextInput>
        <TextInput
          maxLength={16}
          placeholder="Repita a Senha"
          secureTextEntry={true}
          style={tailwind('bg-gray-200 w-full text-base p-2 mt-2 rounded-md')}
          onChangeText={(text) => setValue('newPasswordConfirmation', text)}
        ></TextInput>

        <TouchableOpacity
          style={tailwind(
            'bg-blue-800 w-full px-4 py-3 items-center rounded-md mt-2'
          )}
          onPress={handleSubmit(onSubmit)}
        >
          <Text style={tailwind('text-white font-medium')}>Alterar</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}
