import React from 'react'
import { Image, Platform, Text, TouchableOpacity, View } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import tailwind, { getColor } from 'tailwind-rn'
import { BASE_URL, cpfMask, fetchWithToken } from '../utils/common'
import Avatar from '../../assets/default-avatar-1.png'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import MyModal from '../components/MyModal'
import EmailRegister from '../components/EmailRegister'
import useModal from '../utils/useModal'
import DadosBancarios from '../components/DadosBancarios'
import { Modal, Portal, Provider } from 'react-native-paper'
import * as ImagePicker from 'expo-image-picker'
import useSWR from 'swr'

export default function DadosPessoais({ userToken, route }) {
  const colaboradorCodigo = route.params.colaboradorVinculo.codigo

  const url = `${BASE_URL}/api/colaborador?codigo=${colaboradorCodigo}`

  const {
    data: { colaborador, colaboradorVinculo },
    error,
    mutate,
  } = useSWR([url, userToken], fetchWithToken, { initialData: route.params })

  if (!colaborador || error)
    return (
      <View
        style={tailwind(
          'bg-blue-800 w-full h-full flex items-center justify-center'
        )}
      >
        <Text
          style={tailwind(
            'flex justify-center items-center text-gray-300 text-lg'
          )}
        >
          Sem dados para exibir
        </Text>
      </View>
    )

  const listData = [
    {
      title: 'LOTACAO',
      value: colaboradorVinculo.lotacao ? colaboradorVinculo.lotacao : '-',
      iconName: 'map-marker-radius',
    },
    {
      title: 'CPF',
      value: cpfMask(colaboradorVinculo.cpf),
      iconName: 'card-account-details',
    },
    {
      title: 'CÓDIGO',
      value: colaboradorVinculo.codigo,
      iconName: 'identifier',
    },
    {
      title: 'E-MAIL',
      value: colaborador.email ? colaborador.email : '-',
      iconName: 'email',
    },
  ]

  const [
    modalOpen_EmailRegister,
    setModalOpen_EmailRegister,
    toggle_EmailRegister,
  ] = useModal({
    defaultVisible: false,
  })
  const [modalOpen_DadosBancarios, toggle_DadosBancarios] = useModal({
    defaultVisible: false,
  })

  const changeSelfie = async () => {
    if (Platform.OS !== 'web') {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
      if (status !== 'granted') {
        alert(
          'Precisamos de sua permissão para acessar suas fotos. Acesse as configurações do seu dispositivo e conceda o acesso'
        )
        return
      }
    }
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 0.25,
      base64: true,
    })
    if (result.cancelled) return
    if (result.width < 200 || result.height < 200) {
      alert('Image deve ser maior que 200px')
      return
    }
    // 1 - convert to base64
    // 2 - send to api
    // 3 - show new photo
    const res = await fetch(`${BASE_URL}/api/colaborador/selfie`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: userToken },
      body: JSON.stringify({ selfie: result.base64 }),
    })
    const resData = await res.json()
    //setSelfie(resData.metadata.thumbnailUrl)
    mutate({
      colaborador: { ...colaborador, selfie: resData.metadata },
      colaboradorVinculo: { ...colaboradorVinculo },
    })
    alert('Foto de perfil atualizada com sucesso!')
  }

  return (
    <Provider>
      <View style={tailwind('bg-blue-800 w-full h-full')}>
        <MyModal
          isVisible={modalOpen_EmailRegister}
          toggle={toggle_EmailRegister}
        >
          <EmailRegister
            userToken={userToken}
            callback={(newEmail) => {
              setModalOpen_EmailRegister(false)
              mutate({
                colaborador: { ...colaborador, email: newEmail },
                colaboradorVinculo: { ...colaboradorVinculo },
              })
            }}
          />
        </MyModal>
        <Portal>
          <Modal
            style={tailwind('mx-6 -mt-10')}
            visible={modalOpen_DadosBancarios}
            onDismiss={toggle_DadosBancarios}
          >
            <DadosBancarios userToken={userToken} />
          </Modal>
        </Portal>
        <FlatList
          style={tailwind('px-2 py-4')}
          ListHeaderComponent={() => (
            <View
              style={tailwind(
                'flex justify-center items-center mb-2 p-4 border-b-2 border-gray-300'
              )}
            >
              <Image
                source={
                  colaborador.selfie && colaborador.selfie.thumbnailUrl
                    ? { uri: colaborador.selfie.thumbnailUrl }
                    : Avatar
                }
                style={tailwind('w-32 h-32 rounded-full')}
              />
              {/* <TouchableOpacity
                onPress={changeSelfie}
                style={tailwind('flex-row items-center py-1')}
              >
                <Text
                  style={tailwind('font-semibold text-blue-600 uppercase mr-1')}
                >
                  Alterar
                </Text>
                <MaterialCommunityIcons
                  name="square-edit-outline"
                  size={15}
                  color={getColor('blue-600')}
                />
              </TouchableOpacity> */}
              <Text
                style={tailwind(
                  'text-center text-base font-bold text-blue-800 mt-2'
                )}
              >
                {colaborador.nome}
              </Text>
              <Text
                style={tailwind(
                  'text-center text-sm font-bold text-blue-800 px-4 py-1 bg-blue-200 rounded-full mt-2'
                )}
              >
                {colaboradorVinculo.cargo}
              </Text>
            </View>
          )}
          contentContainerStyle={tailwind('bg-white rounded-lg')}
          ItemSeparatorComponent={() => (
            <View style={tailwind('border border-gray-300')} />
          )}
          keyExtractor={(item) => item.title}
          data={listData}
          renderItem={({ item }) => (
            <View style={tailwind('flex-row items-center p-4')}>
              <MaterialCommunityIcons
                name={item.iconName}
                size={30}
                color={getColor('blue-800')}
              />
              <View style={tailwind('flex justify-center ml-3')}>
                <Text style={tailwind('text-xs font-bold text-gray-600')}>
                  {item.title}
                </Text>
                {item.title === 'DADOS BANCÁRIOS' && false && (
                  <TouchableOpacity
                    style={tailwind('flex-row items-center py-1')}
                    onPress={toggle_DadosBancarios}
                  >
                    <Text
                      style={tailwind(
                        'font-semibold text-blue-600 uppercase mr-1'
                      )}
                    >
                      Ver / Alterar
                    </Text>
                    <MaterialCommunityIcons
                      name="square-edit-outline"
                      size={15}
                      color={getColor('blue-600')}
                    />
                  </TouchableOpacity>
                )}
                {item.title === 'E-MAIL' && (
                  <>
                    <Text style={tailwind('text-base text-blue-800 font-bold')}>
                      {item.value}
                    </Text>
                    <TouchableOpacity
                      style={tailwind('flex-col py-1')}
                      onPress={toggle_EmailRegister}
                    >
                      <View style={tailwind('flex-row items-center')}>
                        <Text
                          style={tailwind(
                            'font-semibold text-blue-600 uppercase mr-1'
                          )}
                        >
                          Alterar
                        </Text>
                        <MaterialCommunityIcons
                          name="square-edit-outline"
                          size={15}
                          color={getColor('blue-600')}
                        />
                      </View>
                    </TouchableOpacity>
                  </>
                )}
                {item.title !== 'E-MAIL' && (
                  <Text style={tailwind('text-base text-blue-800 font-bold')}>
                    {item.value}
                  </Text>
                )}
              </View>
            </View>
          )}
        />
      </View>
    </Provider>
  )
}
