import React from 'react'
import { AuthProvider } from './src/contexts/AuthContext'
import Navigation from './src/navigation'
import { StatusBar } from 'react-native'

export default function App() {
  return (
    <AuthProvider>
      <Navigation />
      <StatusBar barStyle="dark-content" backgroundColor="transparent" />
    </AuthProvider>
  )
}
