import React, { useState } from 'react'
import { Platform, Text, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import tailwind, { getColor } from 'tailwind-rn'
import { AntDesign } from '@expo/vector-icons'
import YearMonthPicker from '../components/YearMonthPicker'
import { BASE_URL } from '../utils/common'
import * as FileSystem from 'expo-file-system'
import * as Sharing from 'expo-sharing'
import { ActivityIndicator } from 'react-native-paper'

export default function FolhadePontoScreen({ userToken }) {
  const [yearMonth, setYearMonth] = useState({
    year: new Date().getUTCFullYear(),
    month: new Date().getUTCMonth() + 1,
  })

  const [generating, setGenerating] = useState(false)

  const generateFolhadePontoAndShare = async () => {
    setGenerating(true)
    try {
      const res = await fetch(
        `${BASE_URL}/api/folhaPonto?ano=${yearMonth.year}&mes=${
          yearMonth.month - 1
        }`,
        {
          method: 'GET',
          headers: { Authorization: userToken },
        }
      )
      const { pdfData } = await res.json()

      if (Platform.OS === 'web') {
        var binary = atob(pdfData.replace(/\s/g, ''))
        var len = binary.length
        var buffer = new ArrayBuffer(len)
        var view = new Uint8Array(buffer)
        for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i)
        }
        var blob = new Blob([view], { type: 'application/pdf' })
        var url = URL.createObjectURL(blob)
        window.open(url)
      } else {
        const pdfPath = FileSystem.cacheDirectory + 'folhaPonto.pdf'
        await FileSystem.writeAsStringAsync(pdfPath, pdfData, {
          encoding: FileSystem.EncodingType.Base64,
        })
        await Sharing.shareAsync(pdfPath)
      }
      console.log('generated folha ponto!')
    } catch (e) {
      alert(e.message)
    }
    setGenerating(false)
  }

  return (
    <View style={tailwind('flex-col bg-gray-200 h-full w-full')}>
      <View style={tailwind('bg-yellow-300 mx-5 my-4 px-5 p-2 rounded-lg')}>
        <Text style={tailwind('text-black text-gray-800 mb-1 font-bold')}>
          ATENÇÃO
        </Text>
        <Text style={tailwind('text-gray-800 text-xs')}>
          Preencha a folha conforme os dias e horários trabalhos. O gestor do
          contrato ou supervisor deve assiná-la.
        </Text>
      </View>

      <View style={tailwind('bg-white mx-5 mb-4 p-2 rounded-lg')}>
        <View>
          <YearMonthPicker
            onChange={(data) => setYearMonth(data)}
            currentYearMonth={yearMonth}
          />
        </View>
      </View>

      {!generating && (
        <TouchableOpacity
          disabled={generating}
          style={tailwind(
            `p-6 mx-5 bg-white border border-gray-400 rounded-xl flex-row items-center ${
              generating ? 'opacity-50' : ''
            }`
          )}
          onPress={generateFolhadePontoAndShare}
        >
          <AntDesign name="printer" size={30} color={getColor('blue-800')} />
          <Text style={tailwind('text-blue-800 text-xl font-bold ml-4')}>
            Gerar
          </Text>
        </TouchableOpacity>
      )}

      {generating && (
        <View
          style={tailwind(
            'p-6 mx-5 bg-white border border-gray-400 rounded-xl flex-row items-center'
          )}
        >
          <ActivityIndicator color={getColor('blue-800')} />
          <Text style={tailwind('text-blue-800 text-xl font-bold ml-4')}>
            Carregando...
          </Text>
        </View>
      )}
    </View>
  )
}
