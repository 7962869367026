import React from 'react'
import { FlatList, Text, TouchableOpacity, View } from 'react-native'
import tailwind, { getColor } from 'tailwind-rn'
import { FontAwesome5 } from '@expo/vector-icons'

const listData = [
  { id: 'Dados Pessoais', title: 'Dados Pessoais', iconName: 'user-alt' },
  { id: 'Contracheques', title: 'Contracheques', iconName: 'receipt' },
  { id: 'Folha de Ponto', title: 'Folha de Ponto', iconName: 'calendar-check' },
  {
    id: ' Declaração de Rendimento',
    title: 'Declaração de Rendimento',
    iconName: 'file-invoice',
  },
]

const FlatListHeader = (response, navigation) => (
  <View
    style={tailwind(
      'flex justify-center bg-white px-6 py-4 rounded-xl border border-gray-400'
    )}
  >
    <View style={tailwind('flex-col mb-2')}>
      <Text style={tailwind('text-gray-500 uppercase text-xs')}>Nome</Text>
      <Text style={tailwind('text-blue-800 font-bold uppercase')}>
        {response.nome}
      </Text>
    </View>
    <View style={tailwind('flex-col mb-2')}>
      <Text style={tailwind('text-gray-500 uppercase text-xs')}>Cargo</Text>
      <Text style={tailwind('text-blue-800 font-bold uppercase')}>
        {response.cargo}
      </Text>
    </View>
    <View style={tailwind('flex-col mb-2')}>
      <Text style={tailwind('text-gray-500 uppercase text-xs')}>Lotação</Text>
      <Text style={tailwind('text-blue-800 font-bold uppercase')}>
        {response.lotacao.split(' - ').slice(1).join(' ')}
      </Text>
    </View>
    <View style={tailwind('flex-col mb-2')}>
      <Text style={tailwind('text-gray-500 uppercase text-xs')}>Código</Text>
      <Text style={tailwind('text-blue-800 font-bold uppercase')}>
        {response.codigo}
      </Text>
    </View>
    <View style={tailwind('flex-col mb-2')}>
      <Text style={tailwind('text-gray-500 uppercase text-xs')}>Admissão</Text>
      <Text style={tailwind('text-blue-800 font-bold uppercase')}>
        {response.dataAdmissao}
      </Text>
    </View>
    <View style={tailwind('flex-row')}>
      <TouchableOpacity
        onPress={async () => {
          navigation.navigate('Vinculos do Colaborador')
        }}
        style={tailwind(
          'border border-blue-500 p-2 rounded-md items-center mr-2 flex-row'
        )}
      >
        <FontAwesome5
          name="exchange-alt"
          size={15}
          color={getColor('blue-500')}
          style={tailwind('mr-2 font-bold')}
        />
        <Text style={tailwind('text-blue-500 uppercase font-bold')}>
          Alterar Vínculo
        </Text>
      </TouchableOpacity>
    </View>
    {/* <View style={tailwind('flex-col')}>
      <Text style={tailwind('text-gray-500 uppercase text-xs')}>E-MAIL</Text>
      <Text style={tailwind('text-blue-800 font-bold')}>
        {response.email ? (
          response.email
        ) : (
          <TouchableOpacity style={tailwind('flex-row items-center')}>
            <Text
              style={tailwind('font-semibold text-blue-600 uppercase py-1')}
            >
              Cadastrar Agora
            </Text>
            <MaterialIcons
              name="keyboard-arrow-right"
              size={20}
              color={getColor('blue-600')}
            />
          </TouchableOpacity>
        )}
      </Text>
    </View> */}
  </View>
)

export default function HomeItems({
  colaborador,
  colaboradorVinculo,
  navigation,
}) {
  return (
    <FlatList
      style={tailwind('pt-4 px-2')}
      keyExtractor={(item) => item.id}
      data={listData}
      renderItem={({ item }) => (
        <TouchableOpacity
          key={item.title}
          style={tailwind(
            'mt-4 p-6 bg-white border border-gray-400 rounded-xl flex-row items-center w-full'
          )}
          onPress={() =>
            navigation.navigate(item.title, { colaborador, colaboradorVinculo })
          }
        >
          <FontAwesome5
            name={item.iconName}
            size={30}
            color={getColor('blue-800')}
          />
          <Text style={tailwind('text-blue-800 text-xl font-bold ml-4')}>
            {item.title}
          </Text>
        </TouchableOpacity>
      )}
      ListHeaderComponent={() => FlatListHeader(colaboradorVinculo, navigation)}
    />
  )
}
