import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ActivityIndicator, Text, TextInput, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import tailwind, { getColor } from 'tailwind-rn'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import {
  confirmResetPassword,
  generateResetPasswordCode,
} from '../utils/common'

export default function ResetPassword({ navigation }) {
  const { register, handleSubmit, setValue, errors } = useForm()
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0)
  const [error, setError] = useState('')

  useEffect(() => {
    register('email', {
      required: true,
      pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
    })
  }, [])

  if (step === 1) {
    register('newPassword', { required: true, minLength: 6, maxLength: 32 })
    register('resetCode', { required: true, minLength: 6, maxLength: 32 })
  }

  const onSubmit = async (data) => {
    setLoading(true)
    error && setError('')

    if (step === 0) {
      const { success, message } = await generateResetPasswordCode(data.email)
      success ? setStep(1) : setError(message)
    }

    if (step === 1) {
      const { success, message } = await confirmResetPassword(data)
      success ? setStep(2) : setError(message)
    }

    setLoading(false)
  }

  return (
    <View
      style={tailwind(
        'flex-col justify-center items-center px-4 bg-blue-800 h-full w-full'
      )}
    >
      <View
        style={tailwind('flex-col items-center bg-white rounded-lg p-4 w-full')}
      >
        <MaterialCommunityIcons
          name={step < 2 ? 'lock-reset' : 'check-circle'}
          size={50}
          color={getColor('blue-800')}
          style={tailwind('text-center')}
        />
        <Text
          style={tailwind(
            'p-2 text-xl font-bold text-gray-800 flex items-center justify-center text-blue-800'
          )}
        >
          {step < 2 ? 'Recuperar acesso' : 'Senha alterada'}
        </Text>

        {step === 0 && (
          <>
            <Text
              style={tailwind('text-center font-semibold text-gray-600 p-2')}
            >
              Informe seu e-mail para recuperar seu acesso.
            </Text>

            <Text
              style={tailwind('text-center font-semibold text-gray-600 p-2')}
            >
              Um código de 6 dígitos será enviado para sua caixa de entrada.
            </Text>

            <TextInput
              keyboardType="email-address"
              placeholder="Email"
              style={tailwind(
                `text-blue-800 p-4 w-full my-4 text-lg rounded bg-gray-300 ${
                  errors.email ? 'border border-red-500 text-red-500' : ''
                }`
              )}
              onChangeText={(text) => setValue('email', text)}
            />
          </>
        )}

        {step === 1 && (
          <>
            <Text style={tailwind('font-semibold text-gray-600 p-2')}>
              Informe o código
            </Text>

            <TextInput
              keyboardType="number-pad"
              placeholder="000000"
              maxLength={6}
              style={tailwind(
                `text-blue-800 p-4 w-full my-4 text-lg rounded bg-gray-300 font-bold text-xl tracking-widest text-center ${
                  errors.resetCode ? 'border border-red-500 text-red-500' : ''
                }`
              )}
              onChangeText={(value) => setValue('resetCode', value)}
            />

            <Text style={tailwind('font-semibold text-gray-600')}>
              Digite sua nova senha
            </Text>

            <TextInput
              minLength={6}
              maxLength={32}
              keyboardType="default"
              secureTextEntry={true}
              placeholder="Nova senha"
              style={tailwind(
                `text-blue-800 p-4 w-full my-4 text-lg rounded bg-gray-300 ${
                  errors.newPassword ? 'border border-red-500 text-red-500' : ''
                }`
              )}
              onChangeText={(text) => setValue('newPassword', text)}
            />
          </>
        )}

        {loading && <ActivityIndicator size="large" color="#000" />}

        {!!error && (
          <Text style={tailwind('mb-6 text-red-600 font-bold')}>{error}</Text>
        )}

        {!loading && step < 2 && (
          <TouchableOpacity
            style={tailwind(
              'bg-blue-800 w-full px-8 py-4 rounded-lg flex items-center w-full'
            )}
            onPress={handleSubmit(onSubmit)}
          >
            <Text
              style={tailwind('text-white text-lg font-semibold uppercase')}
            >
              CONTINUAR
            </Text>
          </TouchableOpacity>
        )}

        {!loading && step === 2 && (
          <TouchableOpacity
            style={tailwind('bg-blue-800 w-full px-8 py-4 rounded-lg mt-4')}
            onPress={() => navigation.navigate('Entrar')}
          >
            <Text
              style={tailwind(
                'text-white text-lg justify-center items-center flex font-semibold rounded-full'
              )}
            >
              Entrar
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}
